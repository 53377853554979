<script>
import {
  TO_LOGIN,
} from '@/constant'
export default {
  components: {},
  props: {},
  data() {
    return {
      TO_LOGIN
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() { },
  mounted() { }
};
</script>
<template>
  <ModuleWrapper>
    <div class='wrapper'>
      <h1 class="title">让企业家专注自己的事业</h1>
      <h4 class="subTitle">早早财专家即刻为您解答!</h4>
      <a class="login" :href="TO_LOGIN">
        <a-button class="btn" type="primary" status="danger">
          立即体验
        </a-button>
      </a>
    </div>
  </ModuleWrapper>
</template>

<style lang="less" scoped>
.module {
  padding: 73px 0 117px;

  .wrapper {
    width: 100%;
    height: 323px;
    padding: 105px 0 67px;
    background-image: url(@/assets/join-hibug.png);
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
      line-height: 54px;
      text-align: justify;
      letter-spacing: -0.38px;
      color: #FFFFFF;
    }

    .subTitle{
      font-size: 18px;
      line-height: 54px;
      text-align: justify;
      letter-spacing: -0.38px;
      margin-bottom: 10px;
      color: #FFFFFF;
    }

    .btn {
      width: 173px;
      height: 50px;
      border-radius: 5px;
      font-size: 16px;
    }
  }
}
</style>
