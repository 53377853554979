import {createApp} from 'vue'
import App from './App.vue'
import router from '@/router'
import ArcoVue from '@arco-design/web-vue';
import {Icon} from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
// 清楚浏览器默认样式
import '@/reset.css'
// 额外引入图标库
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 引入全局状态
import {createPinia} from 'pinia'
// 全局组件
import ModuleWrapper from '@/components/moduleWrapper.vue';
import Collaborationist from '@/components/collaborationist.vue';
import JoinHiBug from '@/components/joinHiBug.vue';

const IconFont = Icon.addFromIconFontCn({src: 'https://at.alicdn.com/t/c/font_3776522_gu7k2mowilo.js'});

const pinia = createPinia()

const app = createApp(App)

app
    .component("ModuleWrapper", ModuleWrapper)
    .component("Collaborationist", Collaborationist)
    .component("IconFont", IconFont)
    .component("JoinHiBug", JoinHiBug)

app
    .use(ElementPlus)
    .use(ArcoVue)
    .use(ArcoVueIcon)
    .use(router)
    .use(pinia)
    .mount('#app')
