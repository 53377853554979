import { createRouter, createWebHistory } from "vue-router";

// 企业版
export const ENTERPRISE_HOME_INDEX = "/edu"
export const ENTERPRISE_PRICE = "/price"
export const ENTERPRISE_CUSTOMER_CASE = "/customerCase"
export const ENTERPRISE_ABOUT_US = "/edu/aboutUs"

// 高校版
export const SCHOOL_HOME_INDEX = "/"
export const SCHOOL_ABOUT_US = "/aboutUs"

export const ZAOZAOCAI_LOGIN = "https://www.admin.zaozaocai.com/"
// 公司
export const COMPANY_HOME_INDEX = "/company"
export const COMPANY_CONTACT_US = "/company/contactUs"
export const COMPANY_PRIVACY_STATEMENT = "/company/privacyStatement"
export const COMPANY_SERVICE_TERMS = "/company/serviceTerms"
export const COMPANY_DATA_SAFETY = "/company/dataSafety"
export const COMPANY_EXCEPTIONS = "/company/exceptions"
export const COMPANY_JOIN_US = "/company/joinUs"

const routes = [
  {
    // 登录默认跳转到企业版
    path: SCHOOL_HOME_INDEX,
    name: "home",
    component: () => import("@/views/Home"),
    children: [
      // 高校版
      {
        path: SCHOOL_HOME_INDEX,
        name: "高校版首页",
        component: () => import("@/views/schoolHomeIndex"),
      },
      {
        path: SCHOOL_ABOUT_US,
        name: "高校版关于我们",
        component: () => import("@/views/enterpriseAboutUs"),
      },
      {
        path: COMPANY_HOME_INDEX,
        name: "公司",
        component: () => import("@/views/company"),
        children: [
          {
            path: COMPANY_CONTACT_US,
            name: "联系我们",
            component: () => import("@/views/company/views/contactUs"),
          },
          {
            path: COMPANY_PRIVACY_STATEMENT,
            name: "隐私声明",
            component: () => import("@/views/company/views/privacyStatement"),
          },
          {
            path: COMPANY_SERVICE_TERMS,
            name: "服务条款",
            component: () => import("@/views/company/views/serviceTerms"),
          },
          {
            path: COMPANY_DATA_SAFETY,
            name: "数据安全",
            component: () => import("@/views/company/views/dataSafety"),
          },
          {
            path: COMPANY_EXCEPTIONS,
            name: "免责条款",
            component: () => import("@/views/company/views/exceptions"),
          },
          {
            path: COMPANY_JOIN_US,
            name: "加入我们",
            component: () => import("@/views/company/views/joinUs"),
          },
        ]
      },
      // 企业版默认从企业版首页开始
      {
        path: ENTERPRISE_HOME_INDEX,
        name: "企业版首页",
        component: () => import("@/views/enterpriseHomeIndex"),
      },
      {
        path: ENTERPRISE_PRICE,
        name: "企业版价格",
        component: () => import("@/views/enterprisePrice"),
      },
      {
        path: ENTERPRISE_CUSTOMER_CASE,
        name: "企业版客户案例",
        component: () => import("@/views/enterpriseCustomerCase"),
      },
      {
        path: ENTERPRISE_ABOUT_US,
        name: "企业版关于我们",
        component: () => import("@/views/enterpriseAboutUs"),
      },
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import("@/views/enterpriseAboutUs")
  }
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});
// 路由守卫做404处理
router.beforeEach((to, from, next) => {
  //检查路由是否存在
  if (!router.hasRoute(to.name)) {
    router.push("/404");
    return;
  }

  next()
})
router.afterEach((to, from) => {
  // 在公司页 切换页面就不移动到顶部
  const noToTopPage = [COMPANY_CONTACT_US, COMPANY_PRIVACY_STATEMENT, COMPANY_SERVICE_TERMS, COMPANY_DATA_SAFETY, COMPANY_JOIN_US, COMPANY_EXCEPTIONS]
  if (noToTopPage.includes(from.fullPath) && noToTopPage.includes(to.fullPath)) {
    return
  }
  // 其他页面切换页面时 回到页面顶部
  document.documentElement.scrollTop = 0
})
export default router;
