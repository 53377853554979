import {
  ENTERPRISE_HOME_INDEX,
  ENTERPRISE_PRICE,
  ENTERPRISE_CUSTOMER_CASE,
  ENTERPRISE_ABOUT_US,
  SCHOOL_HOME_INDEX,
  SCHOOL_ABOUT_US,
  COMPANY_HOME_INDEX,
  COMPANY_CONTACT_US,
  COMPANY_PRIVACY_STATEMENT,
  COMPANY_SERVICE_TERMS,
  COMPANY_DATA_SAFETY,
  COMPANY_EXCEPTIONS,
  COMPANY_JOIN_US
} from "@/router"

// 登录跳转
const TO_LOGIN = COMPANY_CONTACT_US
const TO_EDU_LOGIN = COMPANY_CONTACT_US

// 注册跳转
const TO_REGISTER = COMPANY_CONTACT_US
const TO_EDU_REGISTER = COMPANY_CONTACT_US

// 全局
const ENTERPRISE = "企业版"
const SCHOOL = "高校版"

// 定义哪些页面的顶部栏显示是企业版
const ShowEnterpriseTopBar = [
  ENTERPRISE_HOME_INDEX,
  ENTERPRISE_PRICE,
  ENTERPRISE_CUSTOMER_CASE,
  ENTERPRISE_ABOUT_US,
  COMPANY_HOME_INDEX,
  COMPANY_CONTACT_US,
  COMPANY_PRIVACY_STATEMENT,
  COMPANY_SERVICE_TERMS,
  COMPANY_DATA_SAFETY,
  COMPANY_EXCEPTIONS,
  COMPANY_JOIN_US
]

// 定义哪些页面的顶部栏是透明的
const TransparentTopBar = [
  ENTERPRISE_HOME_INDEX,
  COMPANY_CONTACT_US,
  COMPANY_PRIVACY_STATEMENT,
  COMPANY_SERVICE_TERMS,
  COMPANY_DATA_SAFETY,
  COMPANY_JOIN_US,
]

// 定义哪些页面的侧边栏是透明的
const TransparentSideBar = [
  COMPANY_CONTACT_US,
  COMPANY_PRIVACY_STATEMENT,
  COMPANY_SERVICE_TERMS,
  COMPANY_DATA_SAFETY,
  COMPANY_JOIN_US,
]

const TabsMap = {
  [ENTERPRISE]: [
    { name: "首页", url: SCHOOL_HOME_INDEX },
    { name: "价格", url: ENTERPRISE_PRICE },
    // { name: "客户案例", url: ENTERPRISE_CUSTOMER_CASE },
    { name: "关于我们", url: ENTERPRISE_ABOUT_US },
  ],
  [SCHOOL]: [
    { name: "首页", url: SCHOOL_HOME_INDEX },
    { name: "价格", url: ENTERPRISE_PRICE },
    // { name: "客户案例", url: ENTERPRISE_CUSTOMER_CASE },
    { name: "关于我们", url: SCHOOL_ABOUT_US },
  ]
}

// 底部
const AboutEnterprise = [
  { name: "联系我们", url: COMPANY_CONTACT_US },
  { name: "隐私政策", url: COMPANY_PRIVACY_STATEMENT },
  { name: "服务条款", url: COMPANY_SERVICE_TERMS },
  { name: "数据安全", url: COMPANY_DATA_SAFETY },
  { name: "免责条款", url: COMPANY_EXCEPTIONS }
  // { name: "加入我们", url: COMPANY_JOIN_US },
]

const ADDRESS = "北京市海淀区海淀大街甲36号5层5014号-697号"
const PHONE = "155-1092-8028"
const EMAIL = "15510928028@163.com"

const DownloadMethods = [{
  url: "/",
  src: require("@/assets/AppStore.png"),
}, {
  url: "/",
  src: require("@/assets/PlayStore.png")
}]

// 企业认证
const EnterpriseAuth = [
  {
    src: require("@/assets/members/1.png"),
    url: ""
  },
  {
    src: require("@/assets/members/2.png"),
    url: ""
  },
  {
    src: require("@/assets/members/3.png"),
    url: ""
  },
  {
    src: require("@/assets/members/4.png"),
    url: ""
  },
  {
    src: require("@/assets/members/5.png"),
    url: ""
  },
  {
    src: require("@/assets/members/6.png"),
    url: ""
  }
]

// 高校认证
const SchoolAuth = [
  {
    src: require("@/assets/members/1.png"),
    url: ""
  },
  {
    src: require("@/assets/members/2.png"),
    url: ""
  },
  {
    src: require("@/assets/members/3.png"),
    url: ""
  },
  {
    src: require("@/assets/members/4.png"),
    url: ""
  },
  {
    src: require("@/assets/members/5.png"),
    url: ""
  },
  {
    src: require("@/assets/members/6.png"),
    url: ""
  }
]

// 高校版企业认证
const SchoolEnterpriseAuth = [
  {
    src: require("@/assets/schoolHomeIndex/enterprise/1.png"),
    url: "",
    desc: ["高新技术企业"]
  },
  {
    src: require("@/assets/schoolHomeIndex/enterprise/2.png"),
    url: "",
    desc: ["科技型中小企业"]
  },
  {
    src: require("@/assets/schoolHomeIndex/enterprise/3.png"),
    url: "",
    desc: ["北京海淀园入孵"]
  },
  {
    src: require("@/assets/schoolHomeIndex/enterprise/4.png"),
    url: "",
    desc: ["北京市优秀创业团队"]
  },
  {
    src: require("@/assets/schoolHomeIndex/enterprise/5.png"),
    url: "",
    desc: ["华为云初创伙伴"]
  },
  {
    src: require("@/assets/schoolHomeIndex/enterprise/6.png"),
    url: "",
    desc: ["OTEC", "创业大奖"]
  },
  {
    src: require("@/assets/schoolHomeIndex/enterprise/9.png"),
    url: "",
    desc: ["ISO27001", "信息安全管理体系认证"]
  },
  {
    src: require("@/assets/schoolHomeIndex/enterprise/8.png"),
    url: "",
    desc: ["ISO20000", "服务管理体系认证"]
  },
  {
    src: require("@/assets/schoolHomeIndex/enterprise/7.png"),
    url: "",
    desc: ["ISO9001", "质量管理体系认证"]
  },
  {
    src: require("@/assets/schoolHomeIndex/enterprise/10.png"),
    url: "",
    desc: ["AAA", "信用评级"]
  },
]

// 企业版首页——用户都在说
const UsersWords = [
  {
    name: "韩总",
    occupation: "龙电科技CTO",
    content: "“我们团队都是技术背景，对于财务一窍不通。在早期经营过程中，有太多次收到早早财的财务预警，有的是我们记账会计的疏忽大意，有的是我们经营过程中缺乏的经验，早早财自动识别，帮我们避免了税务罚款风险。”",
    src: require("@/assets/enterpriseIndex/userHeaders/1.png")
  },
  {
    name: "姜总",
    occupation: "宝滋食品CEO",
    content: "“我们在初创期并不理解食品行业的财务特点，库存商品周转率及应收账款周转率有很多次都是早早财的预警和提示让我们非常快速的度过风险期，2023年的高速发展离不开早早财的辅佐和帮助。”",
    src: require("@/assets/enterpriseIndex/userHeaders/2.png")
  },
  {
    name: "王总",
    occupation: "素郡主服装服饰CEO",
    content: "“早早财的看板让我能清晰的看到库存有多少、滞销的产品有哪些，我能够及时的决策生产爆款产品处理掉滞销品，这让我的仓库有了监管，资金回笼快多了。”",
    src: require("@/assets/enterpriseIndex/userHeaders/3.png")
  },
  {
    name: "洪总",
    occupation: "艾兰斯科技CEO",
    content: "“早早财的AI政策分析非常好用，帮助我们分析了财务状况，给我们一个符合政策要求的列表，根据早早财的规划，我们提前准备，顺利拿到了国高新的荣誉。”",
    src: require("@/assets/enterpriseIndex/userHeaders/4.png")
  },
  {
    name: "贾总",
    occupation: "泓盈集团COO",
    content: "“早早财的AI问答非常方便，我可以随时随地提问而不需要等待我的会计服务，节省了很大的沟通成本。”",
    src: require("@/assets/enterpriseIndex/userHeaders/5.png")
  },

]

// 企业版——价格页
const PersonService = [
  "财务数据的跟踪和报告",
  "财务数据的可视化呈现",
  "风险预警",
  "早早AI咨询",
  "专家咨询3次",
]

const EnterpriseService = [
  "财务数据管理和分析功能",
  "多维度分析和高级预测",
  "定制化报表和数据分析",
  "数据存储和用户权限控制",
  "数据安全和备份功能",
  "自动化预测和决策支持",
]

const PrivateService = [
  "私有云部署",
  "专属技术支持",
  "专属迭代部署",
]

// 常见问题列表
const QuestionList = [
  {
    question: "我的订阅费是多少？",
    answer: "订阅费根据您的团队规模和使用人数进行计算，您可以使用上面的价格计算器进行计算。"
  },
  {
    question: "我能否免费试用HiBug？",
    answer: "当然，您可以试用14天，确定您对产品满意再付费购买。（2022年11月23日备注：现阶段HiBug限时免费试用，我们将所有必备功能上线完全后，会提前给您收费提醒和通知）"
  },
  {
    question: "我可以要求私有化部署吗？",
    answer: "可以的，您可以在页面右侧直接添加客服微信，或在页面右侧的“联系我们” – “给我们留言”您的联系方式和需求，我们将在1个工作日内给您回复。"
  },
  {
    question: "我可以取消订阅吗？",
    answer: "当然，在您付费使用HiBug后，您可以随时取消下一期的订阅。"
  },
  {
    question: "我如何确定我的需求？",
    answer: "您可以添加专属客服微信，我们将根据您的需求进行定制化的推荐，帮助您更合理决策。"
  }
]

// 企业版（高校版）关于我们页面
const TeamMembers = [
  {
    src: require("@/assets/enterpriseAboutUs/members/member1.jpg"),
    name: "刘春江",
    occupation: "算法工程师"
  },
  {
    src: require("@/assets/enterpriseAboutUs/members/member2.jpg"),
    name: "段浩斌",
    occupation: "全栈工程师"
  },
  {
    src: require("@/assets/enterpriseAboutUs/members/member3.jpg"),
    name: "汤时欣",
    occupation: "前端工程师"
  },
  {
    src: require("@/assets/enterpriseAboutUs/members/member4.jpg"),
    name: "陈耀琪",
    occupation: "后端工程师"
  },
  {
    src: require("@/assets/enterpriseAboutUs/members/member7.jpg"),
    name: "陈龙",
    occupation: "前端工程师"
  }
]

const TimeLineInfo = [
  {
    title: "我们的价值观",
    info: "不断创新，通过智能和可靠的财务解决方案为客户创造可持续的财务价值。"
  },
  {
    title: "我们的使命",
    info: "让创业公司专注业务发展，没有财务之忧。"
  },
]


const CustomerWords = UsersWords


export {
  TO_LOGIN,
  TO_REGISTER,
  TO_EDU_LOGIN,
  TO_EDU_REGISTER,
  ShowEnterpriseTopBar,
  TransparentTopBar,
  TransparentSideBar,
  ENTERPRISE,
  SCHOOL,
  TabsMap,
  AboutEnterprise,
  ADDRESS,
  PHONE,
  EMAIL,
  DownloadMethods,
  EnterpriseAuth,
  SchoolAuth,
  SchoolEnterpriseAuth,
  UsersWords,
  PersonService,
  EnterpriseService,
  PrivateService,
  QuestionList,
  TeamMembers,
  TimeLineInfo,
  CustomerWords,
}
