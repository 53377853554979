<script setup>
</script>
<template>
  <div class="module">
    <div class="module-content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="less" >
/* 模块padding */
.module {
  width: 100%;
  padding: 0 152px;

  .module-content {
    max-width: 1303px;
    margin: 0 auto;
  }
}
</style>
