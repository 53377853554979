<script>
export default {
  components: {},
  props: {
    title: String,
    imgArr: Object
  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() { },
  mounted() { }
};
</script>
<template>
  <div class='wrapper'>
    <h1 class="title">{{ title }}</h1>
    <div class="item-list">
      <img class="item" v-for="(item, index) in imgArr" :key="index" :src="item.src">
    </div>
  </div>
</template>

<style lang="less" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  height: 158px;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    letter-spacing: -0.38px;
    color: #000B33;
  }

  .item-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
      height: 41px;
    }
  }
}
</style>