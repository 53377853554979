<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="less">
/* 全局css */
#app {
  height: 100%;
  width: 100%;
}


.logo {
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0em;
}

.sub-title{
  font-size: 20px !important;
}
</style>
